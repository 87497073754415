import React from "react"
import { PrimaryNav } from "./Header"
import "./index.less"
const loadText = require("src/utils").loadText
const url = loadText("url")
const subject = loadText("headerAndFooter").subject
import { lang } from "../../utils"
const menus = [
  {
    name: subject.product,
    children: [
      {
        category: subject.compute,
        children: [url.bmc, url.edcs],
      },
      {
        category: subject.networking,
        children: lang === "en" ? [url.sdn, url.ipt] : [url.sdn, url.ipt],
      },
    ],
  },
  // {
  //   name: subject.solution,
  //   // link: "/contact-us",
  //   className: "sm-pic",
  //   children: [
  //     {
  //       category: subject.usecase,
  //       children: [url.hybridCloud],
  //     },
  //     {
  //       category: subject.industry,
  //       children: [url.gaming, url.media, url.cloudServiceProvider],
  //     },
  //     {
  //       category: subject.emergingMarket,
  //       children: lang === "zh" ? [url.india] : [url.china, url.india],
  //     },
  //   ],
  // },
  {
    name: subject.partner,
    // link: "partner",
    layout: "link",
    children: [
      url.solutionsPartner,
      url.masterAgentPartner,
      url.channelPartner,
    ],
  },
  {
    name: subject.company,
    layout: "link",
    children:
      lang === "zh"
        ? [url.aboutUs, url.join, url.legal, url.contact]
        : [
            url.aboutUs,
            // url.profiles,
            url.join,
            url.contact,
            url.legal,
            // url.ethics,
          ],
  },
  {
    name: subject.resource,
    layout: "link",
    // link: "/support",
    children: [
      url.support,
      {
        name: "云监控",
        link:
          "/云监控——轻松把控企业IT网络中的数字体验，助您看见、了解并改善现网架构及服务",
      },
    ],
  },
]

export default function Index({
  theme,
  logo,
}: {
  theme?: string
  logo?: string
}) {
  return <PrimaryNav logo={logo || "/logo2x.png"} theme={theme} menus={menus} />
}
