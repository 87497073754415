import React, { useEffect } from "react"
import { Helmet as _Helmet } from "react-helmet"
import { lang, loadScriptToHead } from "../utils"
import ReactGA from "react-ga"

const Helmet: any = _Helmet
export function getCookie(name) {
  if (typeof window !== "undefined") {
    const value = `; ${document.cookie}`
    const parts = value.split(`; ${name}=`)
    if (parts.length === 2) return parts.pop().split(";").shift()
  }
}

// const loadGTM = () => {
//   const gaId = hostnameToGtag[window.location.hostname] || "GTM-584G6JH"
//   ;(function (w, d, s, l, i) {
//     w[l] = w[l] || []
//     w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" })
//     var f = d.getElementsByTagName(s)[0],
//       j: any = d.createElement(s),
//       dl = l != "dataLayer" ? "&l=" + l : ""
//     j.async = true
//     j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl
//     f.parentNode.insertBefore(j, f)
//   })(window, document, "script", "dataLayer", gaId)
// }

export const loadZoom = () => {
  // loadGTM()
  if (window.location.host === "www.zenlayer.com") {
    var zi = document.createElement("script")
    zi.type = "text/javascript"
    zi.async = true
    zi.src = "https://ws.zoominfo.com/pixel/zhERMnd23D9lOQ8d7HVW"
    var s = document.getElementsByTagName("script")[0]
    s.parentNode.insertBefore(zi, s)
  }
}
// const loadBaidu = () => {
//   var hm = document.createElement("script")

//   hm.src = "https://hm.baidu.com/hm.js?ffa8142f666702ecc60d4ba1a0079b42"

//   var s = document.getElementsByTagName("script")[0]

//   s.parentNode.insertBefore(hm, s)
// }
const SEO = ({
  title,
  description,
  children,
  keywords = [],
  featuredImage = "/featuredImage/index.jpg",
}: {
  title: string
  description: string
  keywords?: string[]
  featuredImage?: string
  children?: any
}) => {
  const cookieEnabled =
    lang === "en" &&
    getCookie("newConsent1") === "true" &&
    process.env.NODE_ENV === "production"
  // const cookieEnabled = true
  useEffect(() => {
    ;(window as any).dataLayer = (window as any).dataLayer || []
    loadZoom()
  }, [])
  return (
    <Helmet>
      {/* <script
        type="text/javascript"
        src="https://cdn.cookielaw.org/consent/04802fb9-9d79-42c1-ab3a-412332a76c39/OtAutoBlock.js"
      ></script>
      <script
        src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
        data-language="en"
        type="text/javascript"
        charSet="UTF-8"
        data-domain-script="04802fb9-9d79-42c1-ab3a-412332a76c39"
      ></script>
      <script type="text/javascript">function OptanonWrapper() {}</script> */}
      <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
      <meta charSet="utf-8" />
      <meta name="viewport" content="width = 640" initial-scale="1" />
      <meta name="description" content={description} />
      <meta
        name="google-site-verification"
        content="XeAbtwBd2pksHJ_GrqPVpk4fqEGnTqRoGkKoeAAyKl8"
      />
      <meta name="keywords" content={keywords.join(",")} />
      {featuredImage && <meta property="og:image" content={featuredImage} />}
      {featuredImage && <meta name="twitter:image" content={featuredImage} />}
      <title>{title}</title>
      {cookieEnabled && (
        <script type="text/javascript" src="/pardot-trace.js"></script>
      )}
      {lang === "en" && (
        <script type="text/javascript" src="/leadfeeder.js"></script>
      )}
      {children}
      <link rel="icon" href="/favicon.ico" type="image/x-icon" />
      {/* {lang === "zh" && (
        <script type="text/javascript" src="/baidu-trace.js"></script>
      )} */}
      <html lang={lang} />
      {/* {lang === "en" && (
        <script type="text/javascript" src="/cookie.js"></script>
      )}
      {lang === "en" && (
        <script
          type="text/javascript"
          src="//cdn.iubenda.com/cs/ccpa/stub.js"
        ></script>
      )}
      {lang === "en" && (
        <script
          type="text/javascript"
          src="//cdn.iubenda.com/cs/iubenda_cs.js"
          charSet="UTF-8"
          async
        ></script>
      )} */}
    </Helmet>
  )
}

export default SEO
